<template>
    <div class="container">
        <van-swipe class="my-swipe" :autoplay="swipeinfo.intervalMillisecond" lazy-render indicator-color="white">
            <van-swipe-item v-for="(item,index) in imglist" :key="index"><a @click="jump(item)"><img :style="{aspectRatio:swipeinfo.aspectRatio}" :src="item.picUrl" alt=""/></a></van-swipe-item>
        </van-swipe>
    </div>
</template>
<script>
export default {
    data(){
        return{
            imglist:[],
            swipeinfo:{},
        }
    },
    methods:{
        jump(item){
            console.log(item)
            //下面暂时注释，待到数据库完善后解开
            // if(item.beJump===1){
            //     this.$router.push({
            //                     name:item.jumpUrl,
            //                     query:{
            //                         title:item.singleTitle
            //                     }
            //                 })
            // }
            // else{
            //     document.getElementById('test').scrollIntoView()
            // }
            
        },
        async getImgList(){
            const res = await this.$HTTP.get(this, this.$API['API_SLIDESHOW_ANON_ONE'], { 'app_id':'webhaojingchouh5','module_position':1}, {}, true) || {}
            this.imglist=res.detailsList
            this.swipeinfo=res.slideshowMaster
            // console.log('轮播图返回的数据',res.detailsList)
            // console.log('轮播图',this.imglist)
            // console.log('轮播图',this.swipeinfo)
        }
    },
    mounted(){
        //获取图片列表
        this.getImgList()

    }
}
</script>
<style lang="less" scoped>
    .container{
        padding: 0 12px;
        margin: 0 0 0.2rem 0;
        .my-swipe{
            width: 100%;
            height: 4rem;

            .van-swipe-item {
                
                color: #fff;
                font-size: 20px;
                line-height: 4rem;
                text-align: center;
                background-color: white;
                margin-top: 10px;
                img{
                    width: 100%;
                    // height: 4rem;
                    // aspect-ratio:2.25; 宽高比
                    
                  
                }
        }
        } 
    }
    
</style>