import { render, staticRenderFns } from "./swipe.vue?vue&type=template&id=b10c60da&scoped=true&"
import script from "./swipe.vue?vue&type=script&lang=js&"
export * from "./swipe.vue?vue&type=script&lang=js&"
import style0 from "./swipe.vue?vue&type=style&index=0&id=b10c60da&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b10c60da",
  null
  
)

export default component.exports