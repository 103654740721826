<template>
    <div class="home_page">
        <v-header :params="{ title, leftIcon: false }"></v-header>
        
        <div v-show="showweather" style="font-size:0.3rem;background-color:white"><van-icon name="location-o" />{{city}} {{temperature}}℃ {{weather}}</div>
        <van-tabs
            v-model="active"
            animated
            title-inactive-color="#000000"
            title-active-color="#f10400"
            line-width="50%"
            line-height="3px"
            @click="onTabClick"
        >
            <van-tab
                v-for="(item, index) in tab"
                :key="index"
                :title="item.name"
            >
                <!-- 排列五、七彩星 -->
                <!-- 轮播图 -->
                <v-swipe />
                <van-notice-bar v-if="true"
                    scrollable
                    color="red"
                    background="white"
                    left-icon="volume-o"
                    :text="'公告:'+announcement"
                    @click="download"
                    
                />
                <ol class="list_box">
                    <li
                        class="list"
                        v-for="(row, index) in item.list"
                        :key="index"
                    >
                        <div class="title_bar">
                            {{ row.name }}&nbsp;第&nbsp;<span>{{
                                row.periodText
                            }}</span>
                        </div>

                        <div class="content_bar">
                            <div class="l">
                                <span class="red">{{ row.num1 }}</span>
                                <span class="red">{{ row.num2 }}</span>
                                <span class="red">{{ row.num3 }}</span>
                                <span class="red">{{ row.num4 }}</span>
                                <span>{{ row.num5 }}</span>
                                <span v-if="row.num6 || row.num6 == '0'">{{
                                    row.num6
                                }}</span>
                                <span v-if="row.num7 || row.num7 == '0'">{{
                                    row.num7
                                }}</span>
                            </div>

                            <div class="r">
                                {{ row.year }}月{{ row.month }}日<br />
                                <span
                                    @click="
                                        goPage(
                                            'awardImg?lotteryKind=' +
                                                (row.num7 === null ? '5' : '7')
                                        )
                                    "
                                    >查看奖表</span
                                >
                            </div>
                        </div>
                    </li>
                </ol>

                <!-- 分类 -->
                <ol class="class_box" :class="isHide?'more':''">
                    <li
                        class="list"
                        v-for="(item, index) in classify"
                        :key="index"
                        @click="goPageLocal(item.url, tab[active].value)"
                    >
                        <img class="icon" :src="item.icon" />
                        <p class="name">{{ item.name }}</p>
                    </li>
                </ol>
                <div v-if="classify.length > 8" class="moreCon" @click="isHide=!isHide">
                    <p class="txt">{{ isHide ? '展示更多' : '收起全部' }} </p>
                    <van-icon v-if="isHide" name="arrow-down" size="15"/>
                    <van-icon v-else  name="arrow-up" size="15" />

                </div>

              
            </van-tab>
        </van-tabs>
        <button class="buttonshow" @click="showpop">我要投诉</button>
          <!-- 投诉浮窗 -->
        <van-popup v-model="show" round :close-on-click-overlay="false"  :style="{ maxHeight: '80%',width:'80%'}">
            <p>投诉中心</p>
            <van-cell-group inset>
                <van-field
                    v-model="message"
                    rows="3"
                    autosize
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入您需要投诉的内容"
                    show-word-limit
                />
            </van-cell-group>
            <van-field v-model="contact" label="联系方式" placeholder="手机号或微信号或QQ" />
            <van-button type="default" @click="cancel">取消</van-button>
            <van-button type="default" @click="confirm">投诉</van-button>
           
        </van-popup>
        <v-footer ></v-footer>
    </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import vFooter from "@/components/footer.vue";
import isWeixin from "@/utils/utils.js";
import vSwipe from "./swipe.vue";
export default {
    components: {
        vHeader,
        vFooter,
        vSwipe,
    },
    data() {
        return {
            isHide:true,
            announcement:'欢迎下载安卓手机app,网页版和app端的账号数据是共享的,点击这里即可下载,请将h5网站收藏 http://m.haojingchou.cn 在浏览器打开(非微信)。',
            contact:'',
            message:'',
            show:false,
            title: this.$route.meta.title,
            tab: [
                {
                    name: "排列五",
                    value: "5",
                    list: [],
                },
                {
                    name: "七星彩",
                    value: "7",
                    list: [],
                },
            ],
            classify: [
                {
                    name: "高清长条",
                    icon: require("@/assets/images/jingchoutable.png"),
                    url: "awardImg",
                },
                {
                    name: "劲抽奖册",
                    icon: require("@/assets/images/pb.png"),
                    url: "awardBook",
                },
                {
                    name: "经典解梦",
                    icon: require("@/assets/images/dream2.png"),
                    url: "dream",
                },
                {
                    name: "开奖直播",
                    icon: require("@/assets/images/onlive2.png"),
                    url: "onlive",
                },
                {
                    name: "特邀码师",
                    icon: require("@/assets/images/expect.png"),
                    url: "forecast",
                },
                {
                    name: "彩友圈",
                    icon: require("@/assets/images/friendCircle2.png"),
                    url: "friendsCircle",
                },
                {
                    name: "画规",
                    icon: require("@/assets/images/drawingRules.png"),
                    url: "paint",
                },
                {
                  name:'包奖王',
                  icon: require('@/assets/images/prizeKing.png'),
                  url: 'payWayIndex'
                },
                //开发中
                {
                  name:'名人榜',
                  icon: require('@/assets/images/prizeKing.png'),
                  url: 'ranking'
                },
                {
                  name:'万年历',
                  icon: require('@/assets/images/wnl.png'),
                  url: 'wanNianLi'
                },
              {
                name: '签到',
                icon: require('@/assets/images/qiandao.png'),
                url: 'attendance'
              },
                {
                  name:'红人榜',
                  icon: require('@/assets/images/menu_honren.png'),
                  url: 'famousranking'
                },
                {
                  name:'大师榜',
                  icon: require('@/assets/images/menu_dashi.png'),
                  url: 'masterranking'
                },
                {
                  name:'大师规律',
                  icon: require('@/assets/images/menu_guilv.png'),
                  url: 'masterrule'
                },
                {
                  name:'名人堂',
                  icon: require('@/assets/images/menu_guilv.png'),
                  url: 'hallOfFame'
                },
            ],
            showHeader: true,
            city:'',
            temperature:'',
            weather:'',
            showweather:false,//是否接收到天气信息
        };
    },
    computed: {
        active: {
            get() {
                return this.$store.state.common.lotteryKind === "5" ? 0 : 1;
            },
            set(vle) {
                this.$store.commit("common/updateState", [
                    { name: ["lotteryKind"], value: this.tab[vle].value },
                ]);
            },
        },
    },
    created() {
        if(window.localStorage.five!==undefined&&window.localStorage.seven!==undefined){
            // console.log('七星彩',JSON.parse(window.localStorage.seven),'排列五',JSON.parse(window..five))
            this.tab[0].list=[JSON.parse(window.localStorage.five),JSON.parse(window.localStorage.seven)]
            this.tab[1].list=[JSON.parse(window.localStorage.seven),JSON.parse(window.localStorage.five)]
        }
        // let test='1234'
        // console.log(test.includes('11'))
        // console.log(test.includes('14'))
        // console.log(test.includes('23'))
        
        this.onTabClick();
        // window.localStorage.setItem("five", JSON.stringify({"lotteryId":"1594877730390732800","lotteryKind":5,"periodText":"22307期","lotteryContent":"37231","lotteryDate":"2022-11-17T00:00:00.000+08:00","dayOfWeek":4,"lotteryVu":15,"num1":3,"num2":7,"num3":2,"num4":3,"num5":1,"num6":null,"num7":null,"groupStartLabel":0,"year":"11","month":"17","name":"排列五"}))
        //获取本地天气信息
        // this.getlocalweather()

    },
    mounted() {
        //判断网页是什么打开
        let isWeiXin = isWeixin();
        if (isWeiXin) {
            this.showHeader = false;
        }
        if (this.showHeader == false) {
          document.title = "";
        }
    },
    methods: {
        download(){
            console.log('用户点击了下载app端')
            window.location.href = 'http://appdownloads.haojingchou.cn/'
        },
        showpop(){
            this.show=!this.show 
            this.message=''
            this.contact=''
        },
        confirm(){
            this.show=false
            if(this.$store.state.common.loginInfo.access_token===''||this.$store.state.common.loginInfo.access_token===undefined){
                this.$toast('请先登录后反馈');
                setTimeout(() => {
                this.$router.push('login');
                }, 1000)
            }
            else if(this.message===''){
                this.$toast('反馈内容不能为空');
            }
            else{
                this.$toast('反馈成功');
                let data = {
                complainUserId:this.$store.state.common.loginInfo.userId,
                appId:'webhaojingchouh5',
                complainText:this.message,
                contact:this.contact
                }
                const res =  this.$HTTP.post(this, 'API_COMPLAIN_TEXT', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, false)
                if (res.statusCode === 200) {
                    this.$toast('反馈成功！');
                    
                }
                
                
            }
            
        },
        cancel(){
            this.show=false
            this.message=''
            this.contact=''
        },
        goPageLocal(url, value) {
            if (url === "paint" && value === "5") {
              this.goPage("drawingRules");
            } else if (url === "paint" && value === "7") {
              this.goPage("drawingRulesSeven");
            } else {
              this.goPage(url + "?lotteryKind=" + value);
            }
        },
        async onTabClick() {
            
            var data = window.localStorage;
            //通过checksumCode判断是否为新数据
            if (data["checksumCode"] != undefined) {
                let lottery_data =
                    (await this.$HTTP.get(
                        this,
                        this.$API["API_LOTTERY_LATEST_ONE_ALLIN"],
                        { md5chk: data["checksumCode"] }
                    )) || {};
                if (
                    lottery_data.data === null &&
                    lottery_data.statusCode === 200
                ) {
                    // console.log("不为空,已是最新数据为",lottery_data)
                    let five = JSON.parse(data["five"]);
                    let seven = JSON.parse(data["seven"]);
                    this.tab[0].list = [five, seven];
                    this.tab[1].list = [seven, five];
                } else {
                    //console.log("不为空,新数据为",lottery_data)
                    //排列5
                    let five = lottery_data.data.lotteryP5 || {};
                    five.year = five.lotteryDate
                        ? five.lotteryDate.substr(5, 2)
                        : "";
                    five.month = five.lotteryDate
                        ? five.lotteryDate.substr(8, 2)
                        : "";
                    five.name = "排列五";

                    //七星彩
                    let seven = lottery_data.data.lottery7 || {};
                    seven.year = seven.lotteryDate
                        ? seven.lotteryDate.substr(5, 2)
                        : "";
                    seven.month = seven.lotteryDate
                        ? seven.lotteryDate.substr(8, 2)
                        : "";
                    seven.name = "七星彩";

                    this.tab[0].list = [five, seven];
                    this.tab[1].list = [seven, five];

                    data.setItem(
                        "checksumCode",
                        lottery_data.data.checksumCode
                    );
                    data.setItem("five", JSON.stringify(five));
                    data.setItem("seven", JSON.stringify(seven));
                }
            } else {
                let lottery_data =
                    (await this.$HTTP.get(
                        this,
                        this.$API["API_LOTTERY_LATEST_ONE_ALLIN"]
                    )) || {};
                //console.log("为空,数据为",lottery_data)
                //排列5
                let five = lottery_data.data.lotteryP5 || {};
                five.year = five.lotteryDate
                    ? five.lotteryDate.substr(5, 2)
                    : "";
                five.month = five.lotteryDate
                    ? five.lotteryDate.substr(8, 2)
                    : "";
                five.name = "排列五";

                //七星彩
                let seven = lottery_data.data.lottery7 || {};
                seven.year = seven.lotteryDate
                    ? seven.lotteryDate.substr(5, 2)
                    : "";
                seven.month = seven.lotteryDate
                    ? seven.lotteryDate.substr(8, 2)
                    : "";
                seven.name = "七星彩";

                this.tab[0].list = [five, seven];
                this.tab[1].list = [seven, five];

                data.setItem("checksumCode", lottery_data.data.checksumCode);
                data.setItem("five", JSON.stringify(five));
                data.setItem("seven", JSON.stringify(seven));
            }
        },
        async getlocalweather(){
            const res = await this.$HTTP.get(this, this.$API['API_WEATHER_ONE'], {}, {}, true)
            if (res.statusCode === 200) {
                // console.log('本地的天气信息',res)
                this.showweather=true
                this.city=res.data.city
                this.temperature=res.data.temperature
                this.weather=res.data.weather

            }

        }
    },
};
</script>

<style lang="less" scoped>
.van-popup{
    p{
        font-size: 0.3rem;
        text-align: center;
    }
    .van-button{
        width: 4rem;
        height: 1rem;
        font-size: 0.5rem;

        
    }
}
.buttonshow{
    position: fixed;
    bottom: 2.8rem;
    right: 0.1rem;
    border-radius: 50%;
    width: 1.3rem;
    height: 1.3rem;
    font-size: 0.2rem;
    background-color: red;
    color: white;
    border: none;
   
}

.home_page {
    margin-bottom: 60px;
    .van-tabs__line {
        background-color: #dc6b63; /* 不支持线性的时候显示 */
        background-image: linear-gradient(to right, #dc6b63, #9bb77c);
    }
    .list_box {
        
        .list {
            border: 2px solid #dc6b63;
            border-image: -webkit-linear-gradient(to right, #dc6b63, #9bb77c) 30
                30;
            border-image: -moz-linear-gradient(to right, #dc6b63, #9bb77c) 30 30;
            border-image: linear-gradient(to right, #dc6b63, #9bb77c) 30 30;
            font-size: 16px;
            margin: 0 0.3rem 0.2rem 0.3rem;
            padding: 3%;
            .title_bar {
                font-size: 16px;
                span {
                    color: #f10400;
                }
            }
            .content_bar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;
                .l {
                    display: flex;
                    span {
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        border-radius: 50%;
                        color: white;
                        background-color: #5b92e5;
                        margin-right: 5px;
                    }
                    span.red {
                        background-color: #e1665e;
                    }
                }
                .r {
                }
            }
        }
    }
    .class_box {
        display: flex;
        flex-wrap: wrap;
       
        .list {
            width: 25%;
            margin-bottom: 0.3rem;
            // margin-top: 15px;
            .icon {
                display: block;
                margin: auto;
                width: 50px;
            }
            .name {
                margin: 0;
                text-align: center;
                font-size: 0.25rem;
            }
        }
    }

}
.more{
        height: auto;
        max-height: 3.8rem;
        overflow: hidden;
}
.moreCon{
    // float: right;
    // margin-right: 10px;
    margin-left: 30px;
    .txt{
        font-size: 12px;
        display: inline-block;
    }
}
.van-notice-bar{
    font-size:0.3rem;
}
</style>
